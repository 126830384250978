.sec-expertise{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 5rem;
}

.double-circle {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
    border-radius: 50%; /* Ensure it's a circle */
    position: relative;
    background: #d5a8ac;
}

.double-circle::before {
    content: '';
    position: absolute;
    width: 50%; /* Adjust the size of the inner circle */
    height: 50%; /* Adjust the size of the inner circle */
    border-radius: 50%; /* Ensure it's a circle */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #AD000E;
}



@media screen and (min-width: 768px) and (max-width: 1200px){
    .expertise{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .first-expertise{
        width: 80%;
    }
    .sec-expertise{
        width: 90%;
    }
}

@media screen and (max-width: 767px){
    .sec-expertise{
        padding: 2rem .5rem;
    }
}

/* Animation */

@keyframes slideRightToLeft {
    from {
      transform: translateX(12%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-right-to-left {
    animation: slideRightToLeft 0.5s ease-in-out forwards;
  }
  