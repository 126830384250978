.buzzbee{
    width: 100%;
    overflow: hidden;
}

.buzz-img{
    transition: transform 3s ease;
}

@media screen and (min-width:1100px){
    .buzz-img{
        width: 70%;
    }
}

@media screen and (max-width: 767px){
    .buzz-first-comp-cont{
        margin-top: -10px !important;
    }
}