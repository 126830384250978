.contact {
    width: 100%;
    overflow: hidden;
}

#column1 button {
    border: none;
    color: white;
    background: black;
    border-radius: 30px;
}

#column3 {
    display: none;
}


/* Custom styles for Bootstrap modal */
.modal-content {
    background-color: #ffffff;
    /* Modal background color */
    color: #AD000E;
    /* Text color */
    border: 2px solid #AD000E;
    /* Border color */
    border-radius: 15px;
    /* Rounded corners */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    /* Shadow for depth */
    text-align: center;
}

.modal-header {
    border-bottom: none;
    /* Remove default border */
    text-align: center;
}

.modal-body {
    text-align: center;
}

.modal-title {
    text-align: center;
    font-weight: bold;
}

.modal-footer {
    border-top: none;
    /* Remove default border */
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.close {
    display: none;
    /* Hide close button */
}

.close:hover {
    color: #93000a;
    /* Darker shade for hover effect */
}

.form-control {
    text-align: center;
}

.btn-primary {
    background-color: #AD000E;
    /* Button background color */
    border: none;
    color: white;
    /* Text color */
    border-radius: 25px;
    /* Rounded corners */
    display: block;
    /* Ensure button is a block element for centering */
    margin: 0 auto;
}

.btn-primary:hover {
    background-color: #93000a;
    /* Darker shade for hover effect */
}

.btn-secondary {
    background-color: transparent;
    /* Transparent background */
    border: 1px solid #AD000E;
    /* Border color */
    color: #AD000E;
    /* Text color */
    border-radius: 25px;
    /* Rounded corners */
    text-align: center;
}

.btn-secondary:hover {
    background-color: #AD000E;
    /* Button background color on hover */
    color: white;
    /* Text color on hover */
}


@media screen and (max-width: 1000px) {
    #column1 {
        width: 100% !important;
    }

    #column3 input {
        width: 70%;
    }

    #column3 button {
        width: 70% !important;
    }

    #column1 button {
        display: none;
    }

    #column2 {
        display: none;
    }

    #column3 {
        width: 100% !important;
        display: block;
    }
}