.service2{
    width: 100%;
    overflow: hidden;
}

.zoom-container{
    overflow: hidden;
}

.zoom-effect {
    transition: transform 0.3s ease;
  }
  
  .zoom-effect:hover {
    transform: scale(1.1); /* Adjust the scale factor as needed */
  }
  