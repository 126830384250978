.buzz-com2{
    width: 100%;
    overflow: hidden;
}

.imagecontainer {
    display: inline-block;
    position: relative;
    margin: 10px;
    overflow: hidden;
}

.buzzImg {
    width: 100px;
    height: 700px;
    transition: transform 0.5s;
    filter: grayscale(100%);
}

.buzzImg:hover {
    transform: scale(1.2);
    filter: grayscale(0%);
}

#image-names {
    width: 100px;
    height: 700px;
    background-color: black;
    color: white;
    font-size: 20px;
}

.imgcontainer {
    width: 100px; /* Adjust as needed */
    height: 700px; /* Adjust as needed */
    overflow: hidden;
}

@media screen and (max-width:767px){
    .buzzImg {
        width: 100px;
        height: 300px;
    }

    #image-names {
        width: 100px;
        height: 300px;
        font-size: 12px;
    }
    
    .imgcontainer {
        width: 100px; 
        height: 300px; 
        overflow: hidden;
    }
}


@media screen and (max-width:400px){
    .buzzImg {
        width: 100px;
        height: 200px;
    }

    #image-names {
        width: 100px;
        height: 200px;
        font-size: 10px;
    }
    
    .imgcontainer {
        width: 100px; 
        height: 200px; 
        overflow: hidden;
    }
}